import React, {
  useContext, useState, useEffect, useRef, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from '@/contexts/locale-context';
import { ValidationContext } from '@/contexts/validation-context';
import { TelemetryContext } from '@/contexts/telemetry-context';
import Tooltip from '@/components/tooltip';
import TextDiv from '@/components/text-div';
import TooltipIcon from '@/public/icons/tooltip.svg';
import ErrorReportingHOC from '@/components/error-reporting-hoc';

/**
 * TextInput contains an input field, header, error message, and tooltip.
 * Is wrapped in the ErrorReporting HOC.
 * @param {string} inputSelectorId - DOM id, used to identify this element
 * @param {string} title - Displayed above the text field
 * @param {boolean} smallTitle - Small title size
 * @param {string} placeholder - Placeholder text
 * @param {JSX.Element} tooltipContent - If present, show a tooltip button that uses this as content
 * @param {number} tooltipMaxWidth - CSS property for max width of tooltip (if present)
 * @param {number} inputWidth - CSS Percentage width. Defaults to 100
 * @param {number} inputHeight - Input element height in pixels. Defaults to 48
 * @param {string} inputMaxWidth - CSS property for max width of the input field
 * @param {number} titleHeight - CSS Pixel min height of the title. Defaults to 0
 * @param {number} errorHeight - CSS Pixel min height of the error div. Defaults to 20
 * @param {number} errorMessageWidth - Custom width of the error message container in pixels.
 * @param {boolean} errorLeftAligned - Whether or not error message should be left aligned.
 * @param {boolean} showTitleWrapper - Whether or not to render title container element
 * @param {RegExp} numberRegex - Validation regex for a case when input is used for numbers
 * @param {boolean} required - Whether or not this is a required field
 * @param {function} onChange - Optional function called when the input value changes
 * @param {Object} validationRules - Full description in error-reporting.jsx
 * @param {number} maxLength - Maximum characters allowed
 * @param {number} tabIndex - Tab index of text input component
 * @param {boolean} hideValidationErrors - determines whether or not to include spacing for error message
 */
const TextInput = forwardRef(({
  inputSelectorId,
  title,
  smallTitle,
  titleHeight,
  errorHeight,
  placeholder,
  tooltipContent,
  tooltipMaxWidth,
  inputWidth,
  inputMaxWidth,
  inputHeight,
  required,
  onChange,
  onBlur,
  disabled,
  horizontalLayout,
  maxLength,
  isHidden,
  suffixIcon,
  showTitleWrapper,
  numberRegex,
  tabIndex,
  errorMessageWidth,
  errorLeftAligned,
  ariaLabel,
  content, // Passed in automatically, no need to set
  setContent, // Passed in automatically, no need to set
  error, // Passed in automatically, no need to set it
  showCount,
  selected,
  onFocus,
  prefix,
  hideValidationErrors,
  telemetryEvent,
  telemetryValue,
  telemetryCustomProperty,
}, ref) => {
  const { textDirection, translate } = useContext(LocaleContext);
  const validationContext = useContext(ValidationContext);
  const telemetryContext = useContext(TelemetryContext);
  const [chars, setChars] = useState(0);
  const inputRef = useRef(null);

  const inputChanged = (value) => {
    if (numberRegex) {
      // prevents typing in special characters
      if (!value || value.match(numberRegex)) {
        setContent(value);
        onChange(value);
      }
    } else {
      setContent(value);
      onChange(value);
    }
  };

  const sendTelemtryEvent = (value) => {
    if (telemetryEvent) {
      telemetryContext?.sendEvent(telemetryEvent, {
        ...telemetryValue && ({ value_changed: telemetryValue }),
        ...telemetryCustomProperty && ({ [telemetryCustomProperty]: value }),
      });
    }
  };

  useEffect(() => {
    if (selected === inputSelectorId) {
      inputRef?.current?.focus();
    }
  }, [selected]);

  useEffect(() => {
    setChars(content.length);
  }, [content]);

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && validationContext) {
      validationContext.attemptSubmission();
    }
  };

  return (
    <div className={`text-input ${horizontalLayout ? ' horizontal' : ''}`}>
      {showTitleWrapper && (
        <div className="title-wrapper" dir={textDirection}>
          {title ? (
            <div className={`${smallTitle ? 'small-title' : 'title'}`}>
              <TextDiv>
                {`${title}${required === true ? '*' : ''}`}
              </TextDiv>
            </div>
          ) : <div className="title-spacer" />}
          {tooltipContent && (
          <Tooltip
            content={tooltipContent}
            maxWidth={tooltipMaxWidth}
            triggerOnClick
          >
            <div
              className="tooltip-button-wrapper"
              aria-label={translate('constants.tooltipLabels.questionMark')}
              role="button"
              tabIndex={0}
            >
              <TooltipIcon />
            </div>
          </Tooltip>
          )}
        </div>
      )}
      <div className={`input-row-wrapper ${prefix ? 'with-prefix' : ''}`}>
        <div className="input-wrapper">
          {prefix && <span>{prefix}</span>}
          <input
            aria-label={ariaLabel}
            id={inputSelectorId}
            type={isHidden ? 'password' : 'text'}
            dir={textDirection}
            placeholder={placeholder}
            onChange={(event) => inputChanged(event.target.value)}
            onBlur={(event) => {
              onBlur(event.target.value);
              sendTelemtryEvent(event.target.value);
            }}
            required={required}
            value={content}
            tabIndex={tabIndex}
            className={`input-component ${error ? ' input-error' : ''}`}
            onKeyDown={(event) => onKeyDown(event)}
            disabled={disabled}
            ref={inputRef}
            onFocus={() => onFocus(inputSelectorId)}
          />
          {suffixIcon && <div className={`suffix ${textDirection === 'rtl' ? ' rtl' : ''}`} />}
        </div>
      </div>
      <div className="input-messages" ref={ref}>
        {!hideValidationErrors && (
          <div
            className={
            `error${error ? ' visible' : ''}${errorLeftAligned ? ' left' : ''}${textDirection === 'rtl' ? ' rtl' : ''}`
          }
          >
            {error && <TextDiv selectorId={`${inputSelectorId}-error`}>{error}</TextDiv>}
          </div>
        )}
        {chars > 0 && showCount && (
        <div className="character-count">
          {`${chars}/${maxLength}`}
        </div>
        )}
      </div>
      <style jsx>
        {`
          .text-input {
            position: relative;
            width: ${horizontalLayout ? '100' : inputWidth}%;
          }
          
          .input-component {
            text-overflow: ellipsis;
          }
          
          .tooltip-button-wrapper {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: ${smallTitle ? 8 : 3}px;
            width: 35px;
          }

          .character-count {
            text-align: right;
            font-size: var(--font-size-error);
          }

          .input-messages {
            display: flex;
            justify-content: space-between;
            flex: 0 1 ${errorMessageWidth ? `${errorMessageWidth}px` : '100%'};
            white-space: break-spaces;
          }
          
          .input-messages .error {
            flex-basis: 100%;
            word-break: break-word;
          }

          @media only screen and (max-width: 768px) {
            .text-input {
              width: 100%;
            }
            .error.visible {
              font-size: var(--font-size-error);
            }
          }

          @media only screen and (min-width: 768px) {
              .title-spacer {
                height: 32px;
              }
              .error.visible {
                font-size: var(--font-size-error);
              }
            }

          .title {
            color: var(--color-primary-dark);
            display: block;
            font-size: var(--font-size-H2);
            font-weight: var(--font-weight-H2);
            line-height: 2;
          }

          .small-title {
            color: var( --color-title-grey);
            display: block;
            font-size: var(--font-size-label);
            margin-bottom: ${titleHeight ? '0' : '12'}px;
          }

          .title-wrapper {
            align-items: ${titleHeight ? 'flex-start' : 'center'};
            display: flex;
            min-height: ${titleHeight || 0}px;
          }

          .input-wrapper {
            position: relative;
          }

          .input-row-wrapper.with-prefix .input-wrapper {
            display: flex;
            align-items: center;
            column-gap: 5px;
          }

          .suffix {
            position: absolute;
            background: url(${suffixIcon}) no-repeat center center;
            right: 8px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            top: 0;
            margin-top: 15px;
            background-size: contain;
          }

          .suffix.rtl {
            right: auto;
            left: 8px;
          }

          input {
            border-radius: var(--border-radius);
            border: 1px solid var(--color-mid-grey);
            color: var(--color-primary-dark);
            font-size: var(--font-size-body);
            font-weight: var(--font-weight-body);
            height: ${inputHeight}px;
            padding: var(--spacing-small);
            width: 100%;
          }

          input::placeholder { 
            color: var(--color-mid-grey);
            font-size: var(--font-size-address);
          }

          input:disabled { 
            color: var(--color-dark-grey);
            background-color: var(--color-light-grey);
          }


          .input-error {
            border: 1px solid var(--color-error-red);
          }

          .error {
            min-height: ${errorHeight}px;
            margin-top: 3px;
            visibility: hidden;
          }

          .error.visible {
            font-size: var(--font-size-error);
            color: var(--color-error-red);
            visibility: visible;
          }

          .error.left,
          .error.rtl {
            text-align: left;
          }

          .error.left.rtl {
            text-align: right;
          }

          .horizontal .title-wrapper {
            flex: 1;
            height: 40px;
          }
          .horizontal .title {
            font-weight: normal;
            font-size: var(--font-size-tooltip);
          }
          .horizontal input {
            height: 42px;
            font-size: var(--font-size-tooltip);
          }
          @media only screen and (min-width: 768px) {
            .text-input {
              max-width: ${inputMaxWidth || '100%'};
              margin-right: ${inputMaxWidth ? '20px' : '0'};
            }
            .horizontal.text-input {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
            }
            .horizontal .input-row-wrapper {
              width: ${horizontalLayout ? inputWidth : '100'}%;
            }
            .horizontal .title-wrapper {
              justify-content: space-between;
              margin-right: 20px;
              border-right: 1px solid var(--color-line-grey);
            }
          }
        `}
      </style>
    </div>
  );
});

TextInput.defaultProps = {
  inputSelectorId: null,
  title: null,
  smallTitle: false,
  titleHeight: null,
  errorHeight: 20,
  placeholder: '',
  tooltipContent: null,
  tooltipMaxWidth: null,
  inputWidth: 100,
  inputMaxWidth: null,
  inputHeight: 48,
  errorMessageWidth: null,
  errorLeftAligned: false,
  required: false,
  disabled: false,
  horizontalLayout: false,
  maxLength: null,
  ariaLabel: null,
  validationRules: {
    onSubmit: null,
    required: '',
    submitErrors: null,
    tooLongError: '',
  },
  onChange: () => {},
  onBlur: () => {},
  error: null,
  isHidden: false,
  tabIndex: null,
  suffixIcon: null,
  showCount: false,
  selected: null,
  onFocus: () => {},
  showTitleWrapper: true,
  numberRegex: null,
  prefix: null,
  hideValidationErrors: false,
  telemetryEvent: null,
  telemetryValue: null,
  telemetryCustomProperty: null,
};

TextInput.propTypes = {
  inputSelectorId: PropTypes.string,
  title: PropTypes.string,
  smallTitle: PropTypes.bool,
  titleHeight: PropTypes.number,
  errorHeight: PropTypes.number,
  placeholder: PropTypes.string,
  tooltipContent: PropTypes.node,
  tooltipMaxWidth: PropTypes.number,
  inputWidth: PropTypes.number,
  inputMaxWidth: PropTypes.string,
  inputHeight: PropTypes.number,
  errorMessageWidth: PropTypes.number,
  errorLeftAligned: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  horizontalLayout: PropTypes.bool,
  maxLength: PropTypes.number,
  validationRules: PropTypes.shape({
    onSubmit: PropTypes.arrayOf(PropTypes.string),
    requiredError: PropTypes.string,
    submitErrors: PropTypes.arrayOf(PropTypes.string),
    tooLongError: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  error: PropTypes.string,
  isHidden: PropTypes.bool,
  tabIndex: PropTypes.number,
  suffixIcon: PropTypes.string,
  showCount: PropTypes.bool,
  selected: PropTypes.string,
  prefix: PropTypes.string,
  onFocus: PropTypes.func,
  showTitleWrapper: PropTypes.bool,
  numberRegex: PropTypes.shape(RegExp),
  hideValidationErrors: PropTypes.bool,
  telemetryEvent: PropTypes.string,
  telemetryValue: PropTypes.string,
  telemetryCustomProperty: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default ErrorReportingHOC(TextInput);
